import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import SliceZone from "../components/common/SliceZone";
import CtaChargespot from "../components/common/CtaChargespot";
import { constructActiveDoc } from "../helpers";

const Services = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;
  const {
    title,
    description,
    image,
    image_mobile: imageMobile,
    body,
  } = page.data;
  const {
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
  } = settings.data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          description={description}
          image={image}
          imageMobile={imageMobile}
        />
        <SliceZone slices={body} settings={settings} />
        <div className="my-6 px-3 text-center">
          <CtaChargespot
            data={{ chargespotLabel, chargespotLink }}
            className="h5"
          />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query servicesQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicServices(lang: { eq: $lang }) {
      ...prismicServicesFragment
    }
  }
`;

export default withPrismicPreview(Services);
